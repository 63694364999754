import { Component, OnInit } from '@angular/core';
import { FirebaseService } from './services/firebase.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent{
 
  title = 'paramedicManagement';
  constructor(private firebaseService:FirebaseService){
    this.firebaseService.init();
  }
}
