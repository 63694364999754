import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AdalLoginService } from './adal-login.service';
@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {
  private baseUri = environment.httpUrl;
  constructor(private http: HttpClient, private adalLoginService: AdalLoginService) { }
  private buildHeaders() {
    var headers = new HttpHeaders({
      responseType: 'text',
      authorization: "Bearer " + this.adalLoginService.getTokenFromLocalStorage(),
      Accept: 'plain/text'
    });
    return headers;
  }
  get<T>(url, data?): Observable<T> {
    var options = { headers: this.buildHeaders() };
    if (typeof data !== 'undefined') {
      options['params'] = data;
    }
    return this.http.get<T>(this.baseUri + url, options);
  }
  post<T>(url, data): Observable<T> {
    return this.http.post<T>(this.baseUri + url, data, { headers: this.buildHeaders() })
  }
  put<T>(url, data): Observable<T> {
    return this.http.put<T>(this.baseUri + url, data, { headers: this.buildHeaders() })
  }
  delete<T>(url): Observable<T> {
    return this.http.delete<T>(this.baseUri + url, { headers: this.buildHeaders() });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } 
    else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      switch (error.status) {
        case 401: {
          console.log("401 Unauthorized");
          this.adalLoginService.logOut();
          this.adalLoginService.login();
          break;
        }
        default: {
          console.log("move to error");
          this.adalLoginService.logOut();
          this.adalLoginService.login();
          break;
        }
      }
    }
    console.log(errorMessage)
    return throwError(errorMessage);
  }

}
