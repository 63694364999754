import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {

  constructor() { }
  init(){
    firebase.initializeApp(environment.firebaseConfig);
  }
}
