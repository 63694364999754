import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { from, Observable, Subject } from 'rxjs';
import { Paramedic } from '../classes/paramedic';
import { retry, shareReplay, distinctUntilChanged, catchError, switchMap, map, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParamedicsService {
  private paramedicList: Paramedic[];
  private paramedicSource = new Subject<Paramedic[]>();
  public paramedicSource$ = this.paramedicSource.asObservable();
  public lastUpdateDate:Date;

  constructor(private httpHandler: HttpHandlerService) {
    this.getParamedics();
  }
  getParamedics() {
    this.getParamedicsHttpRequest().subscribe((data) => {
      this.paramedicList = this.prepareParamedicListFromData(data.split("\r"));
      this.paramedicSource.next(this.paramedicList);
    })
  }
  getParamedicsHttpRequest(): Observable<any> {
    // return this.http.get('/Uploads/All_roles.csv', {responseType: 'text'});
    return this.httpHandler.get("paramedics/all").pipe(retry(3), catchError((err) => this.httpHandler.handleError(err)));
  }
  prepareParamedicListFromData(paramedicObj:Array<string>): Paramedic[]{
    var paramedicList = [];
    paramedicObj.forEach((paramedicString:string) => {
      let paramedic= paramedicString.split("|");
      paramedicList.push({
        name: paramedic[4] + " " + paramedic[5],
        class: paramedic[2],
        phone: paramedic[6],
        role: paramedic[7]
      })
    });
    var date = paramedicObj[1].split("|")[0];
    date = date.substr(1,date.indexOf(' ')-1);
    this.lastUpdateDate = new Date(date);
    return paramedicList;
  }
  getParamedicsList(): Paramedic[] {
    return this.paramedicList;
  }
  getLastUpdateDate():Date{
    return this.lastUpdateDate;
  }
}
