import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParamedicsComponent } from './components/paramedics/paramedics.component';
import { MsalGuard } from './guards/msal.guard';


const routes: Routes = [
  {path: "",redirectTo: "paramedics",pathMatch: "full"},
  {path: "paramedics",component: ParamedicsComponent, canActivate: [MsalGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
