// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // httpUrl:"http://localhost:4200/",
  httpUrl:"https://api.rotem-ert.appsb.icldig.icl-group.com/",
  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: 'd66b706c-4950-441d-8313-8a4321eb251e',
    expireOffsetSeconds: 320,//15 minutes (60-15 = 45 minutes auth)
    popUp: false,
    cacheLocation: 'localStorage',
    resource: 'https://graph.microsoft.com'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAAaGLOYt7B0TU8by24PKtr2CjqMG-gMds",
    authDomain: "rotem-paramedics.firebaseapp.com",
    databaseURL: "https://rotem-paramedics.firebaseio.com",
    projectId: "rotem-paramedics",
    storageBucket: "rotem-paramedics.appspot.com",
    messagingSenderId: "384013239824",
    appId: "1:384013239824:web:35d31e96ba6b24ee1b6c95",
    measurementId: "G-NM2JMN2MR2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
