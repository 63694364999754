import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ParamedicsService } from 'src/app/services/paramedics.service';
import { Paramedic } from 'src/app/classes/paramedic';
import firebase from 'firebase';
import { AdalLoginService } from 'src/app/services/adal-login.service';

@Component({
  selector: 'app-paramedics',
  templateUrl: './paramedics.component.html',
  styleUrls: ['./paramedics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParamedicsComponent implements OnInit {
  public emergencyList = [];
  public medicList = [];
  private paramedicList: Paramedic[];
  public lastUpdateDate:Date;
  constructor(private paramedicService:ParamedicsService,private loginService: AdalLoginService) {
    this.paramedicService.paramedicSource$.subscribe(data=>{
      this.paramedicList = data;
        //emergencyList{חירום-25 תחובש חירום-26 חירום נהג-27 חובש חירום נהג-28}
      this.emergencyList = this.paramedicList.filter(paramedic=>paramedic.role==25 || paramedic.role==26 || paramedic.role==27 || paramedic.role==28);//מכין רשימה של חירום
        //medicList{חירום-22 חובש חירום-26 חובש חירום נהג-28}
      this.medicList = this.paramedicList.filter(paramedic=>paramedic.role==22 || paramedic.role==26 || paramedic.role==28)//מכין רשימה של חובשים 
      this.lastUpdateDate = this.paramedicService.getLastUpdateDate();
      console.log( this.medicList);
      
    })
  }
  checkLogin(){
    if (this.loginService.isLoggedIn()) { //check if the user is logged in 
    } else { // if the user isnt logged in he is sent to the login service
      this.loginService.login();
    }
  }
  ngOnInit() {
  }
  firebaseLog(evenentName:string){
    const analytics = firebase.analytics();
    analytics.logEvent(evenentName,{});
  }
}
